import React, { useState,useEffect  } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Packages from "../DB/HeaderLinksMore";
import WHosting from "../DB/HeaderLinksWH";
import About from "../DB/HeaderLinksAbout";
import styled from "styled-components";

const StyledMenu = styled.nav`
 	display: ${({ isLoaded }) => (isLoaded ? 'flex' : 'none')};
	display: flex;
	justify-content: center;
	background: #fff;
   transform: ${({ open }) => (!open ? "translateX(-100%)" : "translateX(0)")};	height: 115vh;
	text-align: right;
	padding: 2rem;
	position: fixed;
	top: 0;
	width: 100%;
	left: 0;
	transition: transform 0.3s ease-in-out;
	overflow: hidden;
	@media (max-width: 576px) {
		width: 100%;
		position: fixed;
	}
	li,
	button {
		font-size: 21px;
		font-weight: 500;
		line-height: 1;
	}
	button div {
		width: 2rem;
		height: 0.25rem;
		background: ${({ open }) => (open ? "#0D0C1D" : "#EFFFFA")};
		border-radius: 10px;
		transition: all 0.3s linear;
		transform-origin: 1px;

		:first-child {
			transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
			position: ${({ open }) => (open ? "fixed" : "absolute")};
		}

		:nth-child(2) {
			opacity: ${({ open }) => (open ? "0" : "1")};
			position: ${({ open }) => (open ? "fixed" : "absolute")};
		}

		:nth-child(3) {
			transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
			position: ${({ open }) => (open ? "fixed" : "absolute")};
		}
	}
`;

const Menu = ({ open }) => {
	const [dropdown, setCheck] = useState(false);

	function openMenu() {
		setCheck((prevDropdown) => !prevDropdown);
	}
	const [dropdown2, setCheck2] = useState(false);
	function openMenu2() {
		setCheck2((prevDropdown2) => !prevDropdown2);
	}
	const [dropdown3, setCheck3] = useState(false);
	function openMenu3() {
		setCheck3((prevDropdown3) => !prevDropdown3);
	}

	return (
		<StyledMenu open={open}>
			<nav className="mobile:text-center mt-10 tablet:text-center mobile:w-full tablet:w-full">
				<ul className="sm:flex items-center justify-between text-base text-gray-700 pt-4 lg:pt-0">

		{/* Client Area Button */}
		<div className="flex justify-center mt-4 mb-6">
					<a
						href="https://client.hostylus.com/"
						target="_blank"
						rel="noreferrer nofollow"
					>
						<button className="px-4 py-2 bg-blue-600 shadow-md text-white rounded-3xl font-medium text-sm">
							<span>
								<img className="w-7 inline-block" src="/user.svg" alt="User Icon" />
							</span>
							<span className="inline-block">client area</span>
						</button>
					</a>
				</div>

					<li className="lg:p-4 py-2 px-0 block">
						<Link
							className=" border-b-2 border-transparent hover:border-blue-600"
							to="/"
						>
							Home
						</Link>
					</li>
					<li className="lg:p-4 py-2 px-0 block">
						<Link
							className="border-b-2 border-transparent hover:border-blue-600"
							to="/domains"
						>
							Domain
						</Link>
					</li>
					<li className="lg:p-4 py-2 px-0 block">
						<Link
							className="border-b-2 border-transparent hover:border-blue-600"
							to="/vps-hosting"
						>
							Servers
						</Link>
					</li>
					<li className="lg:p-4 py-2 px-0 block">
						<div className="">
							<button
								type="button"
								className=" inline-flex justify-center focus:outline-none transition duration-150 ease-in-out border-b-2 border-transparent hover:border-blue-600"
								id="options-menu"
								aria-haspopup="true"
								aria-expanded="true"
								onClick={openMenu}
							>
								Web Hosting
								<svg
									className="mt-1 h-5 w-5"
									xmlns="https://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fillRule="evenodd"
										d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
										clipRule="evenodd"
									/>
								</svg>
							</button>
							<div
								className={`mt-2 transition ease-${
									dropdown ? "out" : "in"
								} duration-${dropdown ? "75" : "75"} transform opacity-${
									dropdown ? "100" : "0"
								} scale-${dropdown ? "100" : "95"} h-${
									dropdown ? "full" : "0"
								}`}
							>
								<div
									className="py-1"
									role="menu"
									aria-orientation="vertical"
									aria-labelledby="options-menu"
								>
									{WHosting.map((items, key) => (
										<Link
											key={key}
											to={items.link}
											className={`px-4 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 ${
												dropdown ? "block" : "hidden"
											} pointer-events-${items.disable}`}
											role="menuitem"
										>
											{items.title}
										</Link>
									))}
								</div>
							</div>
						</div>
					</li>
					<li className="lg:p-4 py-1 px-0 block">
						<div className="">
							<button
								type="button"
								className=" inline-flex justify-center focus:outline-none transition duration-150 ease-in-out border-b-2 border-transparent hover:border-blue-600"
								id="options-menu"
								aria-haspopup="true"
								aria-expanded="true"
								onClick={openMenu2}
							>
								About Us
								<svg
									className="mt-1 h-5 w-5"
									xmlns="https://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fillRule="evenodd"
										d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
										clipRule="evenodd"
									/>
								</svg>
							</button>
							<div
								className={`mt-2 transition ease-${
									dropdown2 ? "out" : "in"
								} duration-${dropdown2 ? "75" : "75"} transform opacity-${
									dropdown2 ? "100" : "0"
								} scale-${dropdown2 ? "100" : "95"} h-${
									dropdown2 ? "full" : "0"
								}`}
							>
								<div
									className="py-1"
									role="menu"
									aria-orientation="vertical"
									aria-labelledby="options-menu"
								>
									{About.map((items, key) =>
										items.link.charAt(0) === "/" ? (
											<Link
												key={key}
												to={items.link}
												className={`px-4 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 ${
													dropdown2 ? "block" : "hidden"
												}`}
												role="menuitem"
											>
												{items.title}
											</Link>
										) : (
											<a
												key={key}
												href={items.link}
												className="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
												role="menuitem"
												rel="noreferrer noopener"
												target="_blank"
											>
												{items.title}
											</a>
										)
									)}
								</div>
							</div>
						</div>
					</li>
					<li className="lg:p-4 px-0 block">
						<div className="pt-2">
							<button
								type="button"
								className=" inline-flex justify-center focus:outline-none transition duration-150 ease-in-out border-b-2 border-transparent hover:border-blue-600"
								id="options-menu"
								aria-haspopup="true"
								aria-expanded="true"
								onClick={openMenu3}
							>
								More
								<svg
									className="mt-1 h-5 w-5"
									xmlns="https://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fillRule="evenodd"
										d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
										clipRule="evenodd"
									/>
								</svg>
							</button>
							<div
								className={`mt-1 transition ease-${
									dropdown3 ? "out" : "in"
								} duration-${dropdown3 ? "75" : "75"} transform opacity-${
									dropdown3 ? "100" : "0"
								} scale-${dropdown3 ? "100" : "95"} h-${
									dropdown3 ? "full" : "0"
								}`}
							>
								<div
									role="menu"
									aria-orientation="vertical"
									aria-labelledby="options-menu"
								>
									{Packages.map((items, key) =>
										items.link.charAt(0) === "/" ? (
											<Link
												key={key}
												to={items.link}
												className={`px-4 py-1 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 ${
													dropdown3 ? "block" : "hidden"
												}`}
												role="menuitem"
											>
												{items.title}
											</Link>
										) : (
											<a
												key={key}
												href={items.link}
												className="block px-4 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
												role="menuitem"
												rel="noreferrer noopener"
												target="_blank"
											>
												{items.title}
											</a>
										)
									)}
								</div>
							</div>
						</div>
					</li>
				</ul>
		
			</nav>
		</StyledMenu>
	);
};

Menu.propTypes = {
	open: PropTypes.bool.isRequired,
};

const StyledBurger = styled.button`
	position: absolute;
	top: 1rem;
	right: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 2rem;
	height: 2rem;
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 0;
	z-index: 10;

	&:focus {
		outline: none;
	}

	div {
		width: 2rem;
		height: 0.2rem;
		background: ${({ open }) => (open ? "#0D0C1D" : "#000")};
		border-radius: 10px;
		transition: all 0.3s linear;
		position: relative;
		transform-origin: 1px;
		:first-child {
			transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
		}

		:nth-child(2) {
			opacity: ${({ open }) => (open ? "0" : "1")};
			transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
		}

		:nth-child(3) {
			transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
		}
	}
`;

const Burger = ({ open, setOpen }) => {
	return (
		<StyledBurger
			open={open}
			onClick={() => setOpen(!open)}
			className="desktop:hidden landscape:hidden  mobile:block tablet:block tablet:mt-4"
			style={open ? { position: "fixed" } : { position: "absolute" }}
		>
			<div className="bg-black" />
			<div className="bg-black" />
			<div className="bg-black" />
		</StyledBurger>
	);
};

Burger.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
};

const App = () => {
	const [open, setOpen] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
  
	useEffect(() => {
		setIsLoaded(true);
	}, []);
  
		return (
			<div>
			<div className="hidden mobile:block tablet:block">
				{isLoaded && (
					<>
						<Burger open={open} setOpen={setOpen} />
						<Menu open={open} />
					</>
				)}
			</div>
			</div>
		);
  };

export default App;
