export default [
  {
    icon: "/header/backup.svg",
    link: "/codeguard",
    title: "Backups",
    description: "Protect your website with daily automated backups",
    isExternal:false
  },
  // {
  //   icon: "DNS.svg",
  //   link: "",
  //   title: "DNS Management",
  //   description: "jusy another wordpress website",
  // },

  {
    icon: "/header/seo.svg",
    link: "/marketgoo",
    title: "SEO Tools",
    description: "Improve your Site's traffic and Grow your Business",
    isExternal:false
  },
  {
    icon: "/header/softylusNew.svg",
    link: "https://Softylus.com",
    title: "Software Development",
    description: "If it's hard on you Softylus will software it for you",
    isExternal:true
  },
 
  {
    icon: "/header/ssl.svg",
    link: "/ssl",
    title: "SSL Certificates",
    description:
      "Establish trust and online security for your website visitors and business.",
      isExternal:false
  },
  {
    icon: "/header/proxie.svg",
    link: "https://coldproxy.com",
    title: "Proxies",
    description: "Premium Proxies",
    isExternal:true
  },
  {
    icon: "/header/vpn.svg",
    link: "/vpn",
    title: "VPN Services",
    description: "Secure & Protect Your Web Browsing\n",
    isExternal:false
  },
];
