import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
function nav_li(props) {
  const slug = props.LinkSlug;
  if (slug.charAt(0) === "/") {
    return (
      <li className="font-lexend-deca text-black opacity-70 font-400 text-sm py-2 mobile:text-center">
        <Link className={props.disable} to={props.LinkSlug}>{props.LinkText}</Link>
      </li>
    );
  }
  return (
    <li className="font-lexend-deca text-black opacity-70 font-400 text-sm py-2 mobile:text-center">
      <a className={props.disable} target="_blank" rel="noreferrer noopener" href={props.LinkSlug}>
        {props.LinkText}
      </a>
    </li>
  );
}
nav_li.propTypes = {
  LinkSlug: PropTypes.string.isRequired, // must be a string and defined
  LinkText: PropTypes.string.isRequired, // must be a number and defined
  disable: PropTypes.string, // must be a number and defined
};
export default nav_li;
