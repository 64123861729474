import React from "react";
import Li from "./footer-nav-li";
import links from "../DB/HeaderLinksMore";
function Footer() {
	return (
		<footer>
			<div
				className="px-32 mobile:px-2 tablet:px-6 laptop:px-16 "
				style={{ backgroundColor: "#F8F8F8" }}
			>
				
				<div className="grid grid-cols-4  justify-items-center pt-16 pm-4  mobile:p-4 border-solid border-t-2 border-b-2 mobile:grid-cols-1 mobile:border-t-0">
					<div>
						<img
							style={{ height: 50 + "px", width: 200 + "px" }}
							className="mb-3 w-16 h-16 mobile:mx-auto "
							src="/footer-logo2.svg"
						/>
						<p className="mobile:pb-3 mobile:text-center font-lexend-deca text-black opacity-70 text-sm py-2">
						Our mission at Hostylus is to deliver exceptional web hosting services that meet the unique needs of every user. We offer affordable prices that empower you to achieve your goals without limitations. Experience unparalleled performance, reliability, and support with our hosting solutions, designed to give you the groundbreaking experience you deserve. Join Hostylus today for unbeatable VPS hosting, shared hosting, cloud hosting, and more.

						</p>
						<div className="mt-10 mb-4 flex flex-row">
							<img
								className="mb-3 h-14 w-14 mobile:mx-auto mx-2"
								src="/paypal.svg"
							/>{" "}
							<img
								className="mb-3 h-14 w-14 mobile:mx-auto mx-2"
								src="/visadd.svg"
							/>{" "}
							<img
								className="mb-3 h-14 w-14 mobile:mx-auto mx-2"
								src="/master card.svg"
							/>{" "}
							<img
								className="mb-3 h-14 w-14 mobile:mx-auto mx-2"
								src="/bitcoin.svg"
							/>
						</div>
					</div>
					<div>
						<h2 className="mobile:text-center text-sm text-black font-bold uppercase mb-3">
							hosting services
						</h2>
						<nav>
							<ul>
								<Li LinkText="VPS" LinkSlug="/vps-hosting" />
								<Li
									LinkText="Wordpress Hosting"
									LinkSlug="/wordpress-hosting"
								/>
								<Li LinkText="Business hosting" LinkSlug="/business-hosting" />
								<Li LinkText="Domain Name" LinkSlug="/domains" />
								<Li LinkText="Shared Hosting" LinkSlug="/web-hosting" />
							</ul>
						</nav>
					</div>
					<div>
						<h2 className="mobile:text-center font-bold text-sm text-black uppercase mb-3">
							ABOUT US
						</h2>
						<nav>
							<ul>
								<Li LinkText="Our Company" LinkSlug="/about" />
								{/* <Li LinkText="Contact Us" LinkSlug="/" /> */}
								<Li LinkText="Blog" LinkSlug="/blog" />
								<Li LinkText="Affiliate" LinkSlug="/affiliate" />
							</ul>
						</nav>
					</div>
					<div>
						<h2 className="mobile:text-center font-bold text-sm text-black uppercase mb-3">
							MORE SERVICES
						</h2>
						<nav>
							<ul>
								{links.map((items, key) => (
									<Li key={key} LinkText={items.title} LinkSlug={items.link} />
								))}
							</ul>
						</nav>
					</div>
				</div>
				{/*social media for mobile*/}
				<div className="grid justify-items-center py-2 grid-cols-1 border-b-2 tablet:hidden laptop:hidden desktop:hidden wide-desktop:hidden ">
					<div className="mobile:flex mobile:justify-center py-2">
						<a
							href="https://www.instagram.com/hostylus/"
							rel="noreferrer noopener"
							target="_blank"
						>
							<svg
								className="inline-block   fill-current text-black  opacity-70 mr-2"
								xmlns="https://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 24 24"
							>
								<path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
							</svg>
						</a>
						<a
							href="https://twitter.com/HostylusLLC"
							rel="noreferrer noopener"
							target="_blank"
						>
							<svg
								className="inline-block   fill-current text-black opacity-70 mx-2"
								xmlns="https://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 24 24"
							>
								<path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
							</svg>
						</a>
						<a
							href="https://www.facebook.com/hostylus/"
							rel="noreferrer noopener"
							target="_blank"
						>
							<svg
								className="inline-block   fill-current text-black opacity-70 mx-2"
								xmlns="https://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 24 24"
							>
								<path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
							</svg>
						</a>
					</div>
					<div className="flex justify-start py-2 mobile:justify-center">
						<svg
							className="mr-1 my-1"
							width="20"
							height="13"
							viewBox="0 0 23 16"
							fill="none"
							xmlns="https://www.w3.org/2000/svg"
						>
							<path
								d="M10.8347 10.7435C11.0519 10.8717 11.2963 10.923 11.5136 10.923C11.7308 10.923 11.9752 10.8717 12.1924 10.7435L23 4.5127V12.7948C23 14.5641 21.4793 16 19.6057 16H3.39433C1.52066 16 0 14.5641 0 12.7948V4.5127L10.8347 10.7435Z"
								fill="black"
								fillOpacity="0.7"
							/>
							<path
								d="M3.39455 0H19.6059C21.208 0 22.5657 1.07693 22.8916 2.51285L11.4866 9.07702L0.108839 2.51285C0.434696 1.07693 1.79243 0 3.39455 0Z"
								fill="black"
								fillOpacity="0.7"
							/>
						</svg>
						<h2 className="text-sm font-400 text-black opacity-70">
							info@hostylus.com
						</h2>
					</div>
					<div className="flex justify-start mobile:justify-center py-2">
						<svg
							className=" mr-1 mobile:mx-auto"
							width="19"
							height="25"
							viewBox="0 0 19 25"
							fill="none"
							xmlns="https://www.w3.org/2000/svg"
						>
							<path
								d="M9.5 0C4.26233 0 0 4.23542 0 9.44271C0 16.8417 8.607 24.4812 8.97328 24.8021C9.12422 24.9344 9.31211 25 9.5 25C9.68789 25 9.87578 24.9344 10.0267 24.8031C10.393 24.4813 19 16.8417 19 9.44271C19 4.23542 14.7377 0 9.5 0ZM9.5 14.5833C6.58983 14.5833 4.22222 12.2469 4.22222 9.375C4.22222 6.50312 6.58983 4.16667 9.5 4.16667C12.4102 4.16667 14.7778 6.50312 14.7778 9.375C14.7778 12.2469 12.4102 14.5833 9.5 14.5833Z"
								fill="black"
								fillOpacity="0.7"
							/>
						</svg>
						<h2 className="font-400 text-sm text-black opacity-70 mobile:text-center">
							2090 Lawrenceville Suwanee Rd. Ste A-690.Suwanee, GA 30024
						</h2>
					</div>
				</div>

				<h6 className="text-center py-6 text-sm text-black opacity-70 mobile:py-3">
					©2024 Hostylus. All rights reserved
				</h6>
			</div>
		</footer>
	);
}
export default Footer;
