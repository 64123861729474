import React, { useState } from "react";
import { Link } from "gatsby";
import Packages from "../DB/HeaderLinksMore";
import WHosting from "../DB/HeaderLinksWH";
import About from "../DB/HeaderLinksAbout";
import App from "./mobileMenu";

function Header() {
	const [dropdown, setDropdown] = useState({ wHosting: false, about: false, more: false });

	const toggleDropdown = (menu) => {
		setDropdown((prevState) => ({ ...prevState, [menu]: !prevState[menu] }));
	};
	// useEffect(() => {
	// 	const script = document.createElement('script');
	// 	script.innerHTML = `
	// 		function gtag_report_conversion_add_to_cart(url) {
			
	// 			var callback = function () {
	// 				if (typeof(url) != 'undefined') {
	// 					window.location = url;
	// 				}
	// 			};
	// 			gtag('event', 'conversion', {
	// 				'send_to': 'AW-16624821405/m8ApCNOBpcAZEJ3Jqvc9',
	// 				'event_callback': callback
	// 			});
	// 			return false;
	// 		}

	// 		function gtag_report_conversion_plan_selection(url) {
			
	// 			var callback = function () {
	// 				if (typeof(url) != 'undefined') {
	// 					window.location = url;
	// 				}
	// 			};
	// 			gtag('event', 'conversion', {
	// 				'send_to': 'AW-16624821405/UA_4CJKar8AZEJ3Jqvc9',
	// 				'value': 1.0,
	// 				'currency': 'USD',
	// 				'event_callback': callback
	// 			});
	// 			return false;
	// 		}
	// 	`;
	// 	document.head.appendChild(script);

	// 	return () => {
	// 		document.head.removeChild(script);
	// 	};
	// }, []);
	return (
		<header style={{ zIndex: 10 }}>
			
			<div className="mobile:hidden tablet:hidden lg:px-20 px-6 bg-white shadow flex flex-wrap items-center py-3">
				<div className="flex-1 flex justify-between items-center">
					<Link to="/">
						<img
							className="my-auto ml-20 pl-3 w-1/3 mobile:w-12 mobile:my-2 tablet:ml-11 tablet:pl-0 mobile:ml-2"
							src="https://ik.imagekit.io/softylus/hostylus_RylJbcnlB.png"
							alt="Hostylus Logo"
						/>
					</Link>
				</div>

				<div className="visible mobile:hidden desktop:w-4/5 tablet:w-1/2 mobile:w-1/2 h-full align-middle inline-block text-right">
					<ul className="inline-block mobile:invisible tablet:invisible">
						{[
							{ name: "Home", link: "/" },
							{ name: "Servers", link: "/vps-hosting" },
							{ name: "Domains", link: "/domains" },
						].map((item, index) => (
							<li
								key={index}
								className="inline-block text-sm hover:font-bold delay-50 border-b-2 border-transparent mx-2 px-3 py-3 hover:border-blue-600"
							>
								<Link className="font-semibold hover:font-bold py-7" to={item.link}>
									{item.name}
								</Link>
							</li>
						))}
						{[
							{
								name: "Web Hosting",
								dropdownKey: "wHosting",
								items: WHosting,
							},
							{
								name: "About Us",
								dropdownKey: "about",
								items: About,
							},
							{
								name: "More",
								dropdownKey: "more",
								items: Packages,
							},
						].map((menu, index) => (
							<li
								key={index}
								onMouseEnter={() => toggleDropdown(menu.dropdownKey)}
								onMouseLeave={() => toggleDropdown(menu.dropdownKey)}
								className="inline text-sm hover:font-bold delay-50 border-b-2 border-transparent mx-3 px-2 py-3 hover:border-blue-600"
							>
								<div className="relative inline-block text-left">
									<button
										type="button"
										className="inline-flex justify-center focus:outline-none transition duration-150 ease-in-out font-semibold"
										aria-haspopup="true"
										aria-expanded={dropdown[menu.dropdownKey]}
									>
										{menu.name}
										<svg
											className="-mr-1 ml-2 h-5 w-5"
											xmlns="https://www.w3.org/2000/svg"
											viewBox="0 0 20 20"
											fill="currentColor"
											aria-hidden="true"
										>
											<path
												fillRule="evenodd"
												d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
												clipRule="evenodd"
											/>
										</svg>
									</button>
									<div
										className={`bg-white origin-top-right absolute -right-1/2 mt-3 w-72 rounded-md shadow-xl transition ease-${dropdown[menu.dropdownKey] ? "out" : "in"
											} duration-75 transform ${dropdown[menu.dropdownKey] ? "block" : "hidden"
											} scale-${dropdown[menu.dropdownKey] ? "100" : "95"}`}
									>
										<div className="py-3" role="menu" aria-orientation="vertical">
											{menu.items.map((item, key) => (
												item.isExternal ? (
													<a
														key={key}
														href={item.link}
														className="block px-1 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 pointer-events-auto"
														role="menuitem"
													>
														<div className="flex text-left">
															<img className="w-14 h-14" src={item.icon} alt={item.title} />
															<div>
																<p className="font-semibold text-lg text-gray-800 ">{item.title}</p>
																<span className="text-xs">{item.description}</span>
															</div>
														</div>
													</a>
												) : (
													<Link
														key={key}
														to={item.link}
														className="block px-1 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 pointer-events-auto"
														role="menuitem"
													>
														<div className="flex text-left">
															<img className="w-14 h-14" src={item.icon} alt={item.title} />
															<div>
																<p className="font-semibold text-lg text-gray-800 ">{item.title}</p>
																<span className="text-xs">{item.description}</span>
															</div>
														</div>
													</Link>
												)
											))}

										</div>
									</div>
								</div>
							</li>
						))}
					</ul>

					<a
						href="https://client.hostylus.com/"
						target="_blank"
						rel="noreferrer nofollow"
					>
						<button className="px-4 inline py-2 mr-16 capitalize bg-blue-600 shadow-md ml-1 text-white rounded-3xl font-medium text-sm">
							<span>
								<img className="w-7 inline-block" src="/user.svg" alt="User Icon" />
							</span>
							<span className="inline-block">client area</span>
						</button>
					</a>
				</div>
			</div>
			{/* Mobile Menu */}
			<div className="my-1">
				<div className="grid gap-4 grid-cols-3 laptop:hidden desktop:hidden wide-desktop:hidden">
					<div></div>
					<div className="flex-1 flex justify-between items-center text-center">
						<Link rel="nofollow" to="/" className="m-auto">
							<img
								className="pl-3 w-16 my-4 mobile:w-12 mobile:my-2"
								src="https://ik.imagekit.io/softylus/hostylus_RylJbcnlB.png"
								alt="Hostylus Logo"
							/>
						</Link>
					</div>
					<App />
				</div>

			</div>
		</header>
	);
}

export default Header;
