export default [
  {
    icon: "/header/our-company.svg",
    link: "/about",
    title: "Our Company",
    description: "More than content management system",
  },
  {
    icon: "/header/blog.svg",
    link: "/blog",
    title: "Our Blog",
    description: "Affordable Shared Hosting",
  },
];
