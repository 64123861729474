export default [
  {
    icon: "/header/wordpress.svg",
    link: "/wordpress-hosting",
    title: "WordPress hosting",
    description: "More than content management system",
    disable:"auto"
  },
  {
    icon: "/header/shared.svg",
    link: "/web-hosting",
    title: "Shared hosting",
    description: "Affordable Shared Hosting",
    disable:"auto"
  },
  {
    icon: "/header/busniess.svg",
    link: "/business-hosting",
    title: "Business hosting",
    description: "Boost your online reach",
    disable:"auto"
  }
];
